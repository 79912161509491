import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'

export const toTitleCase = (value) => startCase(camelCase(value))

export const humanize = (value) => toTitleCase(lowerCase(value))

export const itemsToString = (items, delimeter = 'and') =>
    items.length > 1
        ? `${
              items.slice(0, -1).join(', ') + ` ${delimeter} ` + items.slice(-1)
          }`
        : `${items[0]}`
